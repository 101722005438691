<template>
  <div class="block">
    <el-date-picker
      v-model="value"
      type="datetimerange"
      start-placeholder="Start"
      end-placeholder="End"
      format="HH:mm"
      align="right"
      :default-time="['12:00:00']"
      @change="selectedTime(value)"
    />
  </div>
</template>

<script>
export default {
  name: 'DateTimeRange',
  props: {
    timeEntry: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      value: '',
    };
  },
  methods: {
    selectedTime([start, end]) {
      Object.assign(this.timeEntry, {
        startTime: start,
        endTime: end,
      });
    },
  },
};
</script>

<style lang="stylus"
       rel="stylesheet/css"
       scoped
>
::v-deep {
  .el-picker-panel {
    display: show;
  }

  .el-date-editor--datetimerange {
    width: 145px;
  }
}

</style>
